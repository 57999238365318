<template>
  <div class="animated fadeIn">
    <b-card no-body>
      <b-card-header v-b-toggle.filterBody>
        Фильтр
      </b-card-header>
      <b-collapse
        id="filterBody"
        class="card-body"
      >
        <b-form @submit.prevent="onSubmit">
          <b-row>
            <b-col md="6">
              <b-form-group label="Название">
                <b-form-input
                  v-model="filter.name"
                  type="text"
                  autocomplete="false"
                  @input="updateFilter('name')"
                />
              </b-form-group>
            </b-col>
            <!--b-col md="6">
              <b-form-group
                class="form-group-el-select"
                label="Перевозчик"
              >
                <el-select
                  class="form-control"
                  v-model="filter.contractor"
                  name="contractor"
                  placeholder="Начните вводить название компании перевозчика"
                  :multiple="true"
                  clearable
                  :filterable="true"
                  :remote="true"
                  reserve-keyword
                  :remote-method="customersAuctionsContractorsList"
                  :loading="loading"
                  @input="updateFilter('contractor')"
                  @clear="customersAuctionsContractorsList"
                >
                  <el-option
                    v-for="item in contractors"
                    :key="item.value"
                    :label="item.text"
                    :value="item.value"
                  ></el-option>
                </el-select>
              </b-form-group>
            </b-col-->
          </b-row>
        </b-form>
      </b-collapse>
    </b-card>
    <b-card no-body>
      <b-card-header>
        Перевозчики
        <div class="card-header-actions">
          <small class="text-muted">Дата последнего обновления {{lastRefreshTime}} </small>
          <b-button
            :disabled="loading"
            @click="$refs.table.refresh()"
            variant="primary"
            size="sm"
            class="ml-1"
          >
            <i :class="{'fa-spin' : loading}" class="fa fa-refresh" />&nbsp;Обновить
          </b-button>
          <b-button
            v-if="$store.getters.isAdminOrManager"
            variant="primary"
            size="sm"
            to="/contractor-lists-list/add"
            class="ml-2"
          >
            <i class="fa fa-plus" />&nbsp;Добавить
          </b-button>
        </div>
      </b-card-header>
      <b-card-body :class="{'loading' : loading}">
        <v-server-table
          id="dataTable"
          ref="table"
          :columns="columns"
          :options="options"
          :theme="theme"
          @loaded="loading = false"
        >
          <span
            slot="actions"
            slot-scope="props"
          >
            <a
              v-if="$store.getters.isAdminOrManager"
              v-b-tooltip.hover.bottom="'Редактировать'"
              :href="'/contractor-lists-list/contractor-list/id'+props.row.id"
              class="fa fa-pencil fa-lg text-primary table-action-button"
            />
            <a
              v-if="$store.getters.isAdminOrManager"
              v-b-tooltip.hover.bottom="'Копировать'"
              :href="'/contractor-lists-list/contractor-list/copy/id'+props.row.id"
              class="fa fa-copy fa-lg text-primary table-action-button"
            />
            <a
              v-b-tooltip.hover.bottom="'Просмотреть'"
              :href="'/customer/contractor-lists/id' + props.row.id"
              class="fa fa-eye fa-lg text-secondary table-action-button"
            />
            <a
              v-if="$store.getters.isAdmin"
              v-b-tooltip.hover.bottom="'Удалить список'"
              class="fa fa-close fa-lg text-danger table-action-button"
              @click="confirmDeleteContractorsList(props.row.id)"
            />
          </span>
        </v-server-table>
      </b-card-body>
    </b-card>
  </div>
</template>

<script>
import Vue from 'vue';
import {Event, ServerTable} from 'vue-tables-2';
import notifications from '../../../components/mixins/notifications';
import {customersContractorsListsList, customersContractorsListDelete} from '../../../services/api';
import {queryToUrl, URLToQuery} from '../../../services/http';
import moment from 'moment';

Vue.use(ServerTable);

export default {
  name: 'ContractorList',
  components: {
    ServerTable,
    Event,
  },
  mixins: [notifications],
  data: function() {
    return {
      lastRefreshTime: null,
      filter: {
        name: null,
        contractor: [],
        page: 1,
        limit: 10,
      },
      loading: false,
      columns: [
        'name',
        'actions',
      ],
      data: [],
      options: {
        customFilters: [
          'name',
        ],
        requestFunction: (data) => {
          this.loading = true;
          this.filter.page = data.page;
          this.filter.limit = data.limit;
          this.lastRefreshTime = moment().format('DD.MM.YYYY HH:mm:ss');
          return customersContractorsListsList(this.$store.state.user.ownerId, Object.assign(data, {...this.filter}));
        },
        responseAdapter({data}) {
          return {
            data: data.items,
            count: data.totalCount,
          };
        },
        headings: {
          'name': 'Название',
          'actions': '',
        },
        sortable: [],
        filterable: [],
        sortIcon: {
          base: 'fa',
          up: 'fa-sort-asc',
          down: 'fa-sort-desc',
          is: 'fa-sort',
        },
        pagination: {
          chunk: 10,
          edge: true,
          nav: 'fixed',
        },
        texts: {
          count: 'Показаны с {from} по {to} из {count} записей|{count} записей|Одна запись',
          first: 'Первая',
          last: 'Последняя',
          filter: 'Фильтр:',
          filterPlaceholder: 'Поисковый запрос',
          limit: 'Записи:',
          page: 'Страница:',
          noResults: 'Нет совпадающих записей',
          filterBy: 'Фильтр по {column}',
          loading: 'Загрузка...',
          defaultOption: 'Выбор {column}',
          columns: 'Столбцы',
        },
      },
      useVuex: false,
      theme: 'bootstrap4',
      template: 'default',
    };
  },
  created() {
    this.initRouteParams();
  },
  mounted() {
    const queries = this.$router.currentRoute.query;
    if (queries.limit) {
      this.$refs.table.setLimit(queries.limit);
    }
    if (queries.page) {
      this.$refs.table.setPage(queries.page);
    }
    /**
     * Записываем параметр при
     * изменении страницы таблицы
     * и записываем в URL
     * */
    Event.$on('vue-tables.pagination', (data) => {
      this.updateRouteParams();
    });
    Event.$on('vue-tables.limit', (data) => {
      this.filter.page = 1;
      this.filter.limit = data;
      this.updateRouteParams();
    });
  },
  methods: {
    onSubmit() {
      for (const prop in this.filter) {
        const val = this.filter[prop] ? this.filter[prop] : null;
        Event.$emit('vue-tables.filter::' + prop, val);
      }
    },
    updateFilter(field) {
      if (this.timer) {
        clearTimeout(this.timer);
        this.timer = null;
      }
      this.timer = setTimeout(() => {
        const val = this.filter[field] !== '' ? this.filter[field] : null;
        this.updateRouteParams();
        Event.$emit('vue-tables.filter::' + field, val);
      }, 800);
    },
    updateRouteParams() {
      const res = {};
      if (this.filter.contractor.length) {
        res.contractor = this.filter.contractor;
      }
      if (this.filter.name) {
        res.name = this.filter.name;
      }
      if (this.filter.page && this.filter.page !== 1) {
        res.page = this.filter.page;
      } else {
        delete res.page;
      }
      if (this.filter.limit && this.filter.limit !== 10) {
        res.limit = this.filter.limit;
      } else {
        delete res.limit;
      }
      history.pushState({}, document.title, this.$router.currentRoute.path);
      if (Object.keys(res).length !== 0) {
        const url = queryToUrl('', res);
        history.pushState(null, null, url);
      }
      return res;
    },
    confirmDeleteContractorsList(contractorsListId) {
      this.$bvModal
        .msgBoxConfirm('Вы уверены что хотите удалить список подрядчиков?', {
          okTitle: 'Да',
          cancelTitle: 'Назад',
        })
        .then((confirm) => {
          if (confirm) {
            this.deleteContractorsList(contractorsListId);
          }
        });
    },
    async deleteContractorsList(contractorsListId) {
      this.loading = true;
      const response = await customersContractorsListDelete(this.$store.state.user.ownerId, contractorsListId);
      if (response && response.status === 200) {
        this.showSuccess('Список перевозчиков удален');
        this.$refs.table.refresh();
      }
      this.loading = false;
    },
    /**
     * Запись параметров из URL в this.filter
     * */
    initRouteParams() {
      const params = URLToQuery(this.$route.fullPath);
      if (Object.keys(params).length !== 0) {
        if (params.hasOwnProperty('page')) {
          params.page = Number(params.page);
        }
        this.$nextTick(()=> {
          Object.assign(this.filter, params);
        });
      }
    },
  },
};
</script>

<style lang="scss">
</style>
